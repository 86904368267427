.table {
  max-width: 730px;

  thead {
    th {
      &:first-child {
        padding-left: 5px;
      }
    }
  }

  tbody {
    tr {
      cursor: pointer;

      td {
        width: 50%;

        &:first-child {
          padding-left: 5px;
        }
      }
    }
  }
}

.balance {
  display: flex;
  flex-direction: column;
  padding: 14px 0 60px 0;

  .title {
    font-weight: 500;
    font-size: 18px;
    color: #64768f;
    margin-bottom: 15px;
  }

  .value {
    font-weight: 500;
    font-size: 36px;
    color: #263238;
  }
}

.chase {
  display: flex;
  align-items: center;

  img {
    margin-right: 15px;
  }
}
