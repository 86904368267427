.container {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  gap: 30px;

  .card {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 30px;
    flex: 1 1;

    &.small {
      max-width: 450px;
    }

    &.medium {
      min-width: calc(50% - 15px);
      max-width: calc(50% - 15px);
    }

    &.large {
      min-width: 100%;
      max-width: 100%;
    }

    &.cover {
      flex: 1;
    }

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 18px;
      color: #64768f;
      margin-bottom: 30px;
    }
  }
}
