.nav {
  display: flex;
  width: 100%;
  border-bottom: 2px solid #cfd8dc;
  gap: 30px;
  margin-bottom: 46px;

  button {
    font-size: 16px;
    line-height: 19.2px;
    padding: 11px 0 13px 0;
    text-align: center;
    font-weight: 500;
    border: 0;
    outline: none;
    background-color: transparent;
    color: #c8d1dd;
    margin-bottom: -2px;
    cursor: pointer;
    position: relative;

    &.active {
      pointer-events: none;
      color: #64768f;

      &:after {
        content: "";
        position: absolute;
        right: 0;
        left: 0;
        bottom: -1px;
        height: 4px;
        background-color: #0066f2;
        border-radius: 2px;
      }
    }
  }
}
