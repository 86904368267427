.container {
  font-size: 10px;
  color: #64768f;
  margin-bottom: 30px;

  a {
    font-size: 10px;
    color: #64768f;
    text-decoration: none;
    margin: 0 5px;

    &:hover {
      color: black;
    }

    &:first-child {
      margin-left: 0;
    }
  }
}
