.link {
  font-weight: 600;
  color: #0066f2;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  height: 20px;
  line-height: 20px;
  align-items: center;
  font-size: 14px;
}
