.menu {
  width: 30px;
  height: 30px;
  position: relative;

  .btn {
    transition: 0.3s;
    width: 30px;
    height: 30px;
    border: 0;
    cursor: pointer;
    outline: none;
    background: url("../../../../styles/images/icons/elipsis.svg") no-repeat
      center;
    border-radius: 50%;

    &:hover {
      background-color: #f2f2f7;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 186px;
    right: 0;
    top: 100%;
    background: #ffffff;
    box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.05);
    border-radius: 8px;

    .content_btn {
      height: 35px;
      display: flex;
      align-items: center;
      padding: 0 10px;
      color: #64768f;

      img {
        margin: 0 10px 0 0;
      }

      &:hover {
        background-color: #f6f6f6;
        cursor: pointer;
      }
    }
  }
}
