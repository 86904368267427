.content {
  display: flex;
  flex-direction: column;

  .text {
    font-weight: 500;
    font-size: 36px;
    color: #263238;
    margin-bottom: 30px;
  }

  .row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    span {
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #263238;
      width: 50%;

      &:first-child {
        align-items: flex-start;
        color: #64768f;
      }

      img {
        width: 15px;
        cursor: pointer;

        &:hover {
          filter: brightness(0.95);
        }
      }
    }
  }

  .nav {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #c8d1dd;
    padding-top: 15px;

    .action {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      color: #0066f2;
      cursor: pointer;
      font-weight: 400;
      font-size: 14px;

      &:hover {
        filter: brightness(0.95);
      }
    }
  }
}
