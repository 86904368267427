.container {
  background-color: #f6f6f6;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 90;
  height: 0;
  transition: 0.3s;

  &.active {
    overflow: auto;
    height: calc(100vh - 80px);
  }
}
