.container {
  margin-bottom: 30px;
  font-size: 24px;
  font-weight: 600;
  color: #0066f2;

  .title {
    font-size: 24px;
  }

  &.flex {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}
