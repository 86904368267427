.container {
  padding: 60px 30px;
  display: flex;
  min-height: 100%;

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 500;
      font-size: 18px;
      color: #64768f;
      margin-bottom: 30px;

      .btn {
        width: 40px;
        height: 40px;
        cursor: pointer;
        transition: 0.3s;

        &:hover {
          filter: brightness(0.95);
        }
      }
    }

    .card {
      background: #ffffff;
      border-radius: 5px;
      flex: 1;
      padding: 30px;

      .row {
        display: flex;

        &:not(:last-child) {
          margin-bottom: 30px;
        }

        .rs_value {
          font-weight: 500;
          font-size: 36px;
          color: #263238;
          margin-bottom: 30px;
        }

        .calendar {
          display: flex;
          align-items: center;
          font-size: 12px;
          color: #263238;
          gap: 15px;
          padding: 45px 30px 0 30px;

          &.rs {
            padding: 30px 0 0 0;
          }
        }

        .footer {
          display: flex;
          gap: 15px;

          button {
            border: 0;
            height: 37px;
            width: 100px;
            outline: none;
            border-radius: 8px;
            font-size: 14px;
            cursor: pointer;
            color: #fff;
            background-color: #0066f2;
            transition: 0.3s;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 600;
            gap: 8px;
            line-height: 9px;

            &.back {
              background-color: #f5f7f8;
              color: #0066f2;
            }

            &[disabled] {
              background-color: #b9caeb;
              pointer-events: none;
            }

            &:hover {
              filter: brightness(0.95);
            }
          }
        }

        .field {
          position: relative;
          display: flex;
          align-items: center;
          width: 100%;
          max-width: 345px;
          margin-bottom: 35px;

          .preffix {
            position: absolute;
            font-weight: 500;
            font-size: 36px;
            color: #263238;
            left: 0;
            line-height: 43px;
          }

          input {
            width: 100%;
            padding-left: 30px;
            color: #263238;
            font-weight: 500;
            font-size: 36px;
            height: 43px;
            border: 0;
            outline: none;
            border-bottom: 1px solid #cfd8dc;

            &:focus {
              border-color: #0066f2;
            }

            &::placeholder {
              color: #cfd8dc;
            }
          }
        }

        .field_select {
          display: flex;
          flex-direction: column;
          width: 100%;
          max-width: 345px;

          &.rs {
            .title {
              display: flex;
              gap: 15px;
              align-items: center;

              &:after {
                flex: 1;
                content: "";
                border-bottom: 1px solid #cfd8dc;
              }
            }
          }

          .title {
            margin-bottom: 15px;
            line-height: 30px;
            font-size: 14px;
            color: #263238;
          }
        }
      }
    }
  }
}

.sidebar {
  width: 100%;
  max-width: 255px;
  display: flex;
  flex-direction: column;

  .list {
    display: flex;
    flex-direction: column;
    gap: 17px;
    list-style-type: none;

    li {
      position: relative;
      display: flex;
      align-items: center;
      height: 21px;
      color: #b9caeb;
      font-size: 15px;
      cursor: default;

      &:before {
        content: "";
        display: block;
        width: 21px;
        height: 21px;
        border-radius: 50%;
        background-color: #b9caeb;
        margin-right: 15px;
      }

      &:not(:last-child) {
        &:after {
          content: "";
          display: block;
          width: 1px;
          height: 17px;
          background-color: #0066f2;
          position: absolute;
          top: 21px;
          left: 10px;
        }
      }

      &.active {
        color: #263238;

        &:before {
          background-color: #0066f2;
          border: 5px solid #b9caeb;
        }
      }
    }
  }
}
