.container {
  width: 100%;
  display: flex;
  position: relative;

  &.onlyValue {
    pointer-events: none;

    .label {
      pointer-events: none;
      padding: 0;
      border: 0;
    }
  }

  .drop {
    position: absolute;
    transition: 0.3s;
    right: 15px;
  }

  &.active {
    .drop {
      transform: rotate(180deg);
    }
  }

  .menu {
    position: absolute;
    z-index: 2;
    top: 100%;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    overflow: hidden;

    .label {
      border: 0;

      &:hover {
        background-color: #f6f6f6;
      }
    }
  }

  .label {
    background-color: #ffffff;
    position: relative;
    cursor: pointer;
    padding: 0 10px;
    width: 100%;
    height: 67px;
    border: 1px solid #cfd8dc;
    border-radius: 5px;
    display: flex;
    align-items: center;

    .image {
      display: inline-block;
      width: 38px;
      height: 38px;
      margin-right: 15px;
      border-radius: 8px;
      background-color: transparent;
      overflow: hidden;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .txtacc {
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      max-width: 200px;
      text-overflow: ellipsis;

      span {
        margin-top: 5px;
        display: block;
        color: #64768f;
      }
    }
  }

  &.active {
  }
}
